import Vue from 'vue'
import VueRouter from 'vue-router'

import axios from 'axios'

//const 
let routes = [
  { path: '/',redirect:'/login'},//路由重定向
  { path: '/login',component: () => import('../components/Login.vue')},
  { 
    path: '/home',component: () => import('../components/Home.vue'),
    //redirect:'/welcome',
    children:[//前缀一样会 同时展开
      { path: '/welcome',component: () => import('../components/WelcomeAdmin.vue')},//首页
      { path: '/dashboard',component: () => import('../components/WelcomeEnterprise.vue') },//首页
      { path: '/base/baseSet',component:  () => import('../components/baseset/BaseSet.vue')},//基础设置
      { path: '/updatepassword',component:  () => import('../components/user/UpdatePassword.vue')},//修改密码
      { path: '/isystem/user',component:  () => import('../components/user/Users.vue')},//企业用户
      { path: '/isystem/permission',component:  () => import('../components/power/Rights.vue')},//权限菜单
      { path: '/isystem/roleUserList',component:  () => import('../components/power/Roles.vue')},//角色
      { path: '/crm/userList',component:  () => import('../components/crmuser/Crmusers.vue')},//crm客户
      { path: '/crm/userformList',component:  () => import('../components/crmuser/CrmUserAdmin.vue')},//crm客户 admin权限
    ] 
  },
  
  { path: '/sys/forgetpassword',component: () => import('../components/ForgetPassword.vue')},
  { path: '/404',component: () => import('../components/Page404.vue')},
  { path: '/500',component: () => import('../components/Page500.vue')},
  
]

//如果用户没有登录，直接访问url访问特定页面，需要重新导航到登录页面 ----- 导航守卫
const router = new VueRouter({
  routes
})

const whiteList = ['/login','/404','/500','/wecharauth'] // 不重定向白名单
router.beforeEach((to,from,next)=>{
  //to 将要访问的路径
  //from 代表从哪个路径跳转而来
  //next 是一个函数 表示执行
  //next（） 放行   next（‘／login’）强制跳转
  console.log("走导航守卫了1");
  // console.log(from.path);
  // console.log(to.path);
  const tokenStr = window.sessionStorage.getItem('token');
  //已登录
  if(tokenStr){
    console.log("走导航守卫了2");
    // 如果包含tokenStr 从登录页面跳转 直接跳转到首页 /
    if (to.path === '/login') {
      console.log("走导航守卫了4");
      next()
    } else {
      if (to.matched.length === 0) {
        next('/404') // 判断此跳转路由的来源路由是否存在，存在的情况跳转到来源路由，否则跳转到404页面
      }
      const menulist = window.sessionStorage.getItem('menulist');
      // console.log("先获取下menulist，看是否为空 未被渲染过，需要取渲染");
      // console.log(menulist);
      if(menulist === null || menulist === "" || menulist === undefined){//未被渲染过，需要取渲染
        //const menu = window.sessionStorage.getItem('menu');
        //获取菜单
        // console.log('导航守卫-获取菜单');
        // console.log('token===='+tokenStr);
        
        //const {data:res} = axios.get('sys/permission/getUserPermissionByToken?token='+tokenStr);
        //console.log(res);
        // axios.get('sys/permission/getUserPermissionByToken?token='+tokenStr).then((response)=>{
        //   console.log('导航守卫-获取菜单之后');
        //   //console.log(response);
        //   //this.newsList=response.data.data;
        //   window.sessionStorage.setItem('menulist',JSON.stringify(response.data.result.menu));
        //   window.sessionStorage.setItem('auth',JSON.stringify(response.data.result.auth));
        //   let constRoutes = [];
        //   constRoutes = generateIndexRouter(response.data.result.menu);
        //   console.log("动态加载到路由之前看下结果");
        //   //console.log(constRoutes);
        //   routes = routes.concat(constRoutes);
        //   console.log(routes);
        //   router.addRoutes(routes);
        //   console.log("router.addRoutes 之后");
        //   console.log(router);
        //   const redirect = decodeURIComponent(from.query.redirect || to.path)
        //   if (to.path === redirect) {
        //     // hack方法 确保addRoutes已完成 ,set the replace: true so the navigation will not leave a history record
        //     next({ ...to, replace: true })
        //   } else {
        //     // 跳转到目的路由
        //     next({ path: redirect })
        //   }
          
        // }).catch((response)=>{
        //   console.log(response);
        //   //if(res.code !== 200) return this.$message.error(res.message);
        // })

      }
      console.log("走导航守卫了5");
      //response.
      next() // 如果匹配到正确跳转
    }

  //未登录
  }else{
    console.log("走导航守卫了3");
    if (whiteList.indexOf(to.path) !== -1) {//不是去登录页
      console.log("走导航守卫了6");
      next()
    } else {
      // 还没有登录过 则跳转到登录界面
      // next('/login')
      console.log("走导航守卫了7");
      if (to.path.slice(1) !== '') {
        if (to.matched.length === 0) {
          router.push({
            path: '/login'
          })
        } else {
          router.push({
            path: '/login',
            query: {
              redirect: to.path.slice(1)
            }
          })
        }
      } else {
        router.push({
          path: '/login'
        })
      }
    }

  }
  
})


// 生成首页路由
export function generateIndexRouter(data) {
let indexRouter = [{
          path: '/',
          name: 'dashboard',
          //component: () => import('@/components/layouts/BasicLayout'),
          //component: resolve => require(['@/components/layouts/TabLayout'], resolve),
          meta: { title: '首页' },
          redirect: '/dashboard',
          children: [
            ...generateChildRouters(data)
          ]
        }]
  return indexRouter;
}

// 生成嵌套路由（子路由）
function  generateChildRouters (data) {
  const routers = [];
  for (let item of data) {
    // console.log("生成嵌套路由（子路由）");
    // console.log(item);
    let component = "";
    if(item.component.indexOf("layouts")>=0){
       component = "../components/"+item.component;
    }else{
       component = "../components/"+item.component;
    }

    // eslint-disable-next-line
    // let URL = (item.url|| '').replace(/{{([^}}]+)?}}/g, (s1, s2) => eval(s2)) // URL支持{{ window.xxx }}占位符变量
    // if (isURL(URL)) {
    //   item.url = URL;
    // }


    let menu =  {
      path: item.path,
      name: item.name,
      redirect:item.redirect,
      //component: componentPath,
      component: resolve => require([component+'.vue'], resolve),
      //hidden:item.hidden,
      // meta: {
      //   title:item.meta.title ,
      //   icon: item.meta.icon,
      //   url:item.meta.url ,
      //   permissionList:item.meta.permissionList,
      //   keepAlive:item.meta.keepAlive,
      //   /*update_begin author:wuxianquan date:20190908 for:赋值 */
      //   internalOrExternal:item.meta.internalOrExternal,
      //   /*update_end author:wuxianquan date:20190908 for:赋值 */
      //   componentName:item.meta.componentName
      // }
    }
    // if(item.alwaysShow){
    //   menu.alwaysShow = true;
    //   menu.redirect = menu.path;
    // }
    if (item.children && item.children.length > 0) {
      menu.children = [...generateChildRouters( item.children)];
    }
    //--update-begin----author:scott---date:20190320------for:根据后台菜单配置，判断是否路由菜单字段，动态选择是否生成路由（为了支持参数URL菜单）------
    //判断是否生成路由
    if(item.route && item.route === '0'){
      // console.log(' 不生成路由 item.route：  '+item.route);
      // console.log(' 不生成路由 item.path：  '+item.path);
    }else{
      // console.log("打印menu");
      // console.log(menu);
      routers.push(menu);
    }
    //--update-end----author:scott---date:20190320------for:根据后台菜单配置，判断是否路由菜单字段，动态选择是否生成路由（为了支持参数URL菜单）------
  }
  // console.log("生成完事");
  // console.log(routers);
  return routers
}
Vue.use(VueRouter)
// console.log("导出router");
// console.log(router);
export default router
