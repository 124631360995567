<template>
  <div id="app">
    <!-- 路由占位符 -->
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'app',
  // mounted(){
  //   if (!!window.ActiveXObject || 'ActiveXObject' in window) {
  //       window.addEventListener('hashchange', () => {
  //           let currentPath = window.location.hash.slice(1)
  //           if (this.$route.path !== currentPath) {
  //               this.$router.push(currentPath);// 主动更改路由界面
  //           }
  //       }, false);
  //   }
  // }
  
}
</script>

<style>
</style>
