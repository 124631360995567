import Vue from 'vue'
import App from './App.vue'
import router from './router'
// import './plugins/element.js'
//导入全局样式
//import './assets/css/element-ui/index.css';// 2.3、引入ElementUI的自定义的主题样式
import './assets/css/global.css'

//导入图标样式
import './assets/fonts/iconfont.css'

import axios from 'axios'

import echarts from 'echarts'

import NProgress from "nprogress";

//引入组件
import {Message} from 'element-ui'



//import { config } from 'vue/types/umd'
//axios.defaults.baseURL = 'http://127.0.0.1:8095/gecrm/'//配置请求的根路径
//axios.defaults.baseURL = 'http://123.57.29.189:8895/'//配置请求的根路径 我的服务器
axios.defaults.baseURL = 'http://101.200.182.227:8095/'//配置请求的根路径 葛总的服务器
//请求拦截器：在请求头中添加Authorization
axios.interceptors.request.use(config=>{
  NProgress.start()//显示进度条
  console.log(config)
  //config.headers.Authorization = window.sessionStorage.getItem('token')

  config.headers = {
    'X-Access-Token' : window.sessionStorage.getItem('token')
  }
  console.log("======axios.interceptors.request.use======"); 
  //在最后必须return config
  return config
})

axios.interceptors.response.use(config=>{
  NProgress.done()//隐藏进度条
  
  //在最后必须return config
  return config
})
Vue.prototype.$http = axios //设置成员 这样每一个vue组建都可以用this都可以访问$http

Vue.prototype.$baseURL = axios.defaults.baseURL

Vue.config.productionTip = false


Vue.prototype.$echarts = echarts

const hasPermission = userPermission =>{
  // let bunPermissionList = window.sessionStorage.getItem('auth')
  // let status = bunPermissionList.includes(userPermission)
  // return status

  let status = false;
  let permission = JSON.parse(window.sessionStorage.getItem('auth'));
  //console.log(permission);
  //console.log(permission.length);
	for (var i = 0; i < permission.length; i++) {
    //console.log("======permission[i].action======");    控制台一直在打印？
    //console.log(permission[i].action);
		if(permission[i].action === userPermission){
			status = true;
			break;
		}
	}
	return status;
}
Vue.prototype.$_has = hasPermission

const getNowFormatDate = currentdate =>{
  var date = new Date();
  var seperator1 = "-";
  var year = date.getFullYear();
  var month = date.getMonth() + 1;
  var strDate = date.getDate();
  if (month >= 1 && month <= 9) {
    month = "0" + month;
  }
  if (strDate >= 0 && strDate <= 9) {
    strDate = "0" + strDate;
  }
  var currentdate = year + seperator1 + month + seperator1 + strDate;
  return currentdate;
};

Vue.prototype.$_getNowFormatDate = getNowFormatDate

//注意：这里使用的$message,所以在使用时候也是this.$message
Vue.prototype.$message = function(msg){
  return Message({
    message: msg,
    duration: 1000
  })
}
//分别对success、warning和error等样式进行设置
Vue.prototype.$message.success = function (msg) {
  return Message.success({
    message: msg,
    duration: 1000
  })
}
Vue.prototype.$message.warning = function (msg) {
  return Message.warning({
    message: msg,
    duration: 2000
  })
}
Vue.prototype.$message.error = function (msg) {
  return Message.error({
    message: msg,
    duration: 3000
  })
}


const isMobile = isMobile=> {
  let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
  return flag;
};
Vue.prototype.$_isMobile = isMobile

const isAdmin = isAdmin=> {
  //let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
  let status = false;
  let admin =JSON.parse(window.sessionStorage.getItem('roles'));
  if(JSON.stringify(admin).indexOf("admin")>0){
     status = true;
  }
  return status;
};
Vue.prototype.$_isAdmin = isAdmin


const IE11RouterFix = {
    methods: {
        hashChangeHandler: function() { this.$router.push(window.location.hash.substring(1, window.location.hash.length)); },
        isIE11: function() { return !!window.MSInputMethodContext && !!document.documentMode; }
    },
    mounted: function() { if ( this.isIE11() ) { window.addEventListener('hashchange', this.hashChangeHandler); } },
    destroyed: function() { if ( this.isIE11() ) { window.removeEventListener('hashchange', this.hashChangeHandler); } }
};


new Vue({
  router,
  mixins: [IE11RouterFix],
  render: h => h(App)
}).$mount('#app')
